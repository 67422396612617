import { required, maxLength } from 'vuelidate/lib/validators'

export default {
  provide: function() {
    return {
      $vv: this.$v
    }
  },
  validations: {
    detailGroup: ['entity.itemDesc'],
    entity: {
      itemDesc: {
        required,
        maxLength: maxLength(100)
      }
    },
    filter: {}
  }
}
