import { TableColumn } from '@/classes'

const Columns = [
  new TableColumn('Item No.', 'ITEMNO', true, 'asc', 'left'),
  new TableColumn('Description', 'ITEMDESC', true, 'asc', 'left'),
  new TableColumn('Active', 'ACTIVE', true, 'asc', 'centered'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]

export { Columns }
